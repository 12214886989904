* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

html {
  background: url(assets/base-screen-background.svg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: transparent !important;
  min-height: 100%;
  height: max-content;
}

#root {
  height: 100%;
}

#toast-container-main {
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  width: 450px;
}

@media (max-width: 599.95px) {
  #toast-container-main {
    left: 0;
    width: 100%;
  }
}

#toast-container-main > div {
  margin-bottom: 10px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: slide-in 0.2s;
  transition: all 0.3s ease;
}

@keyframes slide-in {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

mark {
  background: rgba(255, 184, 0) !important;
}
